<template>
  <div id="user">
    <div class="mine-title">{{ $t('route.user') }}</div>
    <div class="content">
      <div class="cell">
        <span>{{ $t('mine.user_1') }}{{ maskedPhone || maskedEmail }}</span>
      </div>
      <div class="cell">
        <span>{{ $t('mine.user_2') }}</span>
        <a-button type="primary" class="cell-btn" ghost @click="type = 0, show = true">
          {{ $t('mine.user_8') }}
        </a-button>
      </div>
      <div class="cell">
        <span>{{ $t('mine.user_5') }}：{{ info.email?$t('mine.user_6'):$t('mine.user_7')}}</span>
        <a-button type="primary" class="cell-btn" ghost @click="type = 1, show = true">
          {{ info.email?$t('mine.user_8'):$t('mine.user_9') }}
        </a-button>
      </div>
      <div class="cell">
        <span>{{ $t('mine.user_10') }}：{{ info.phone?$t('mine.user_6'):$t('mine.user_7')}}</span>
        <a-button type="primary" class="cell-btn" ghost @click="type = 2, show = true">
          {{ info.phone?$t('mine.user_8'):$t('mine.user_9') }}
        </a-button>
      </div>
    </div>
    <van-overlay :show="show" :lock-scroll="false" z-index="1000">
      <div class="wrapper" @click.stop="dropdown = false">
        <div class="block">
          <van-icon color="#666666" class="close" @click="close" style="cursor: pointer;" size="20px" name="cross" />
          <div class="modal-cont" v-if="type == 0">
            <div class="title">{{ $t('mine.user_4') }}</div>
            <div class="form">
              <van-form @submit="onChange">
                <van-field class="input" type="password" clearable v-model="changePwd.old_pwd"
                           :placeholder="$t('mine.user_11')"
                           :rules="[{ required: true, message: $t('mine.user_11') }]" />
                <van-field class="input" type="password" clearable v-model="changePwd.new_pwd"
                           :placeholder="$t('mine.user_12')"
                           :rules="[{ required: true, message: $t('mine.user_12') }]" />
                <van-field class="input" type="password" clearable v-model="changePwd.repassword"
                           :placeholder="$t('login.tips_2')"
                           :rules="[{ required: true, message: $t('login.tips_2') }]" />
                <van-button color='#2564F6' :disabled="isDisabled(changePwd)" type="primary" native-type="submit"
                            block>{{ $t('common.ok') }}</van-button>
              </van-form>
            </div>
          </div>
          <div class="modal-cont" v-if="type == 1">
            <div class="title">{{ $t('mine.user_5') }}</div>
            <div class="form">
              <van-form @submit="bindEmail">
                <van-field class="input" type="text" clearable v-model="email.email" :placeholder="$t('mine.user_13')"
                           :rules="[{ required: true, message: $t('mine.user_13') }]" />
                <van-field class="input" v-model="email.captcha" clearable :placeholder="$t('login.tips_4')"
                           :rules="[{ required: true, message: $t('login.tips_4') }]">
                  <template #button>
                    <span class="colorBtn" v-show="!isStart"
                          @click.stop="start(email.email, 'changeemail')">{{ $t('login.tips_5') }}</span>
                    <van-count-down ref="countDown" v-show="isStart" @finish="finish" :time="60000" :auto-start="false"
                                    format="ss s" style="color:#2564F6;" />
                  </template>
                </van-field>
                <van-button color='#2564F6' :disabled="isDisabled(email)" type="primary" native-type="submit"
                            block>{{ $t('common.ok') }}</van-button>
              </van-form>
            </div>
          </div>
          <div class="modal-cont" v-if="type == 2">
            <div class="title">{{ $t('mine.user_10') }}</div>
            <div class="form">
              <van-form @submit="bindMobile">
                <div class="dropdown box flex-center-start">
                  <div class="area flex" @click.stop="dropdown = !dropdown">
                    +{{ mobile.area }}
                  </div>
                  <van-field class="input" v-model="mobile.mobile" clearable :placeholder="$t('mine.user_14')"
                             :rules="[{ required: true, message: $t('mine.user_14') }]" />
                  <ul class="dropdown-menu" v-show="dropdown">
                    <li class="flex-center-between" @click.stop>{{ $t('login.area') }}</li>
                    <li class="flex-center-between" :class="{ colorBtn: item.area == mobile.area }" v-for="(item, index) in areaList"
                        :key="index" @click="mobile.area = item.area, dropdown = false">
                      <span v-if="$i18n.locale == 'zh-cn'">{{ item.cn }}</span>
                      <span v-else-if="$i18n.locale == 'zh-tw'">{{ item.tw }}</span>
                      <span v-else>{{ item.en }}</span>
                      <span>+{{ item.area }}</span>
                    </li>
                  </ul>
                </div>
                <van-field class="input" v-model="mobile.captcha" clearable :placeholder="$t('login.tips_4')"
                           :rules="[{ required: true, message: $t('login.tips_4') }]">
                  <template #button>
                    <span class="colorBtn" v-show="!isStart"
                          @click.stop="start(mobile.mobile, 'changemobile', mobile.area)">{{ $t('login.tips_5') }}</span>
                    <van-count-down ref="countDown" v-show="isStart" @finish="finish" :time="60000" :auto-start="false"
                                    format="ss s" style="color:#2564F6;" />
                  </template>
                </van-field>
                <van-button color='#2564F6' :disabled="isDisabled(mobile)" type="primary" native-type="submit"
                            block>{{ $t('common.ok') }}</van-button>
              </van-form>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { LOGINAREA } from '../common/LOGINAREA'
import { message } from 'ant-design-vue';
import { mapState } from 'vuex';
export default {
  name: 'User',
  data() {
    return {
      areaList:Object.freeze(LOGINAREA),
      type: 0,
      show: false,
      dropdown: false,
      isCheck: false,
      isStart: false,
      changePwd: {
        old_pwd: '',
        new_pwd: '',
        repassword: ''
      },
      email: {
        email: '',
        captcha: ''
      },
      mobile: {
        area: '86',
        mobile: '',
        captcha: ''
      }
    }
  },
  computed: {
    ...mapState('user',['info']),
    maskedEmail() {
      // 分隔邮箱地址中的用户名和域名
      const { email } = this.info
      return email&&email.replace(/^(.{0,3}).*@(.*)$/, "$1***@$2")
    },
    maskedPhone() {
      // 隐藏手机号码中的部分数字
      const { phone } = this.info
      return phone&&phone.replace(phone.substring(3, 7), "****")
    }
  },
  methods: {
    onChange() {
      if (this.changePwd.new_pwd !== this.changePwd.repassword) {
        message.warning(this.$t('login.reset'))
        return
      }
      this.$http.post(this.APIURL.PUBLIC.CHANGEPWD, {
        password: this.changePwd.old_pwd,
        newpassword: this.changePwd.new_pwd
      }).then(res => {
        if (res.code == 1) {
          message.success(res.msg);
          setTimeout(() => {
            location.reload()
          }, 1000)
        } else {
          message.error(res.msg);
        }
      })
    },
    bindMobile() {
      this.$http.post(this.APIURL.PUBLIC.BINDMOBILE,
        this.mobile, false).then(res => {
          if (res.code == 1) {
            message.success(res.msg);
            setTimeout(() => {
              location.reload()
            }, 1000)
          } else {
            message.error(res.msg);
          }
        })
    },
    bindEmail() {
      this.$http.post(this.APIURL.PUBLIC.BINDEMAIL,
        this.email, false).then(res => {
          if (res.code == 1) {
            message.success(res.msg);
            setTimeout(() => {
              location.reload()
            }, 1000)
          } else {
            message.warning(res.msg);
          }
        })
    },
    start(text, type, area) {
      if (text) {
        this.$http.post(this.APIURL.PUBLIC.GETCODE,
          {
            text: text,
            area: area,
            type: type,
          }).then(res => {
            if (res.code == '1') {
              this.isStart = true
              this.$refs.countDown.start();
            } else {
              message.warning(res.msg);
            }
          })
      }
    },
    finish() {
      this.isStart = false
      this.$refs.countDown.reset();
    },
    close() {
      this.show = false
    },
    isDisabled(obj) {
      for (const key in obj) {
        if (!String(obj[key])) {
          return true
        }
      }
      return false
    },
  },
}
</script>
<style lang="scss" scoped>
.dropdown {
  position: relative;

  .dropdown-menu {
    border-radius: 3px;
    border: none;
    width: 100%;
    padding: 0 10px 0 20px;
    box-shadow: 0px 2px 12px 0px rgba(48, 49, 51, 0.1);
    max-height: 300px;
    overflow-y: auto;
    display: block;
    position: absolute;
    left: 0;
    top: 110%;

    li {
      color: #333333;
      font-size: 14px;
      padding: 10px 0;
      cursor: pointer;
      border-bottom: 1px solid #D8D8D8;

      &:first-child {
        &:hover {
          color: #333;
        }
      }

      &:hover {
        color: $color;

      }
    }
  }
  @include respond-to('phone'){
    .dropdown-menu {
      li {
        &:hover {
          color: #333333;
        }
      }
    }
  }
}
#user {
  .content {
    margin-top: 20px;
    border-top: 1px solid #EBEBEB;
    .cell {
      color: #666666;
      padding: 20px 0;
      border-bottom: 1px solid #EBEBEB;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:last-child {
        border: none;
      }
      .share-url {
        color: $color;
        user-select: text;
        word-break: break-all;
      }
      .cell-btn{
        min-width: 100px;
        height: 40px;
        color: $color;
        border-radius: 0;
        border-color: $color;
      }
      .copy-btn{
        margin-left: 10px;
      }
    }
  }
  @include respond-to('phone'){
    .content {
      .cell {
        font-size: 14px;
        padding: 12px 0;
        .cell-btn {
          min-width: 80px;
          height: 36px;
        }
      }
    }
  }
}
</style>